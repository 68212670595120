import Service from 'api/Service';
class ProductService extends Service {
  apiResource = '/products';

  /**
   * @title:  Get Products List
   * @description:  This function gets the products list
   * @returns {Object}
   * @example:
   * const products = await ProductService.getProducts();
   */
  getProducts = async () => {
    try {
      const { data } = await this.all('');
      return data;
    } catch (error) {
      console.error(error);
    }
  };
}

export default new ProductService();
