import { useSelector, useDispatch } from 'react-redux';
import { setCheckout } from 'store/actions/checkoutAction';
import { SET_CHECKOUT_TYPE } from 'constants/index';
import { getScoutCode } from 'utils/api/users';

import Icon from 'components/UI/Icon';
import { useEffect } from 'react';

const ScoutCodeField = () => {
  // const params = new URLSearchParams(window.location.search);
  // const sn = params.get('sn');
  const sn = getScoutCode();

  const dispatch = useDispatch();
  const { scoutCode, scoutName } = useSelector((state) => state.checkout);

  const onChange = (e) => {
    if (e.target.name === 'setdefault') {
      console.log('onChange', e.target.checked);
      dispatch(
        setCheckout({
          scoutCode: e.target.checked ? 'default_scout' : '',
          type: SET_CHECKOUT_TYPE.SCOUT_CODE,
        })
      );
      return;
    }
    if (e.target.name === 'setScoutName') {
      dispatch(
        setCheckout({
          scoutName: e.target.value,
          type: SET_CHECKOUT_TYPE.SCOUT_NAME,
        })
      );
      return;
    }
    if (e.target.value.length > 0) {
      dispatch(
        setCheckout({
          scoutCode: e.target.value,
          type: SET_CHECKOUT_TYPE.SCOUT_CODE,
        })
      );
    } else {
      dispatch(
        setCheckout({
          scoutCode: '',
          type: SET_CHECKOUT_TYPE.SCOUT_CODE,
        })
      );
    }
  };

  useEffect(() => {
    if (sn) {
      dispatch(
        setCheckout({
          scoutCode: sn.toLowerCase(),
          type: SET_CHECKOUT_TYPE.SCOUT_CODE,
        })
      );
    }
  }, [sn]);

  return (
    <section className="mb-2.5">
      {location.pathname === '/cart' ||
        (location.pathname === '/checkout' && (
          <div>
            <label
              className="mb-1 text-sm font-normal text-gray-400 font-encode"
              htmlFor="ScoutCode">
              Scout Code
              <span className="text-red-600">*</span>
            </label>
            <input
              id="scoutCode"
              className={`w-full h-10 p-2 bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:border-gray-300 focus:bg-white`}
              name="scoutCode"
              type="text"
              placeholder="Add a scout code"
              value={scoutCode}
              onChange={onChange}
              disabled={scoutCode === 'default_scout'}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 30,
                marginTop: 20,
                marginBottom: 30,
              }}>
              <div style={{ height: 20, width: 20, marginRight: 20, marginBottom: 0 }}>
                <input
                  id="setdefault"
                  className={`w-full h-full bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:border-gray-300 focus:bg-white`}
                  name="setdefault"
                  type="checkbox"
                  checked={scoutCode === 'default_scout'}
                  onChange={onChange}
                />
              </div>
              <label
                className="mb-1 text-lg font-normal text-gray-400 font-encode"
                htmlFor="setdefault">
                I don&apos;t know the Scout Code
              </label>
            </div>
            <div
              style={{ display: scoutCode === 'default_scout' ? 'block' : 'none', marginTop: 0 }}>
              <label
                className="mb-1 text-sm font-normal text-gray-400 font-encode"
                htmlFor="setScoutName">
                Enter Scouts Name
                <span className="text-red-600">*</span>
              </label>
              <input
                id="setScoutName"
                className={`w-full h-10 p-2 bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:border-gray-300 focus:bg-white`}
                name="setScoutName"
                type="text"
                placeholder="Scout's Name"
                value={scoutName}
                onChange={onChange}
              />
            </div>
          </div>
        ))}

      {location.pathname === '/order-completed' && (
        <div className="space-x-1 bg-white rounded py-2.5 px-3.5 text-blue-500 font-bold flex items-center">
          <Icon name="user" size="sm" />
          <h5 className="ml-2">Scout code: {scoutCode}</h5>
        </div>
      )}
    </section>
  );
};

export default ScoutCodeField;
