import PropTypes from 'prop-types';
import { Helmet as Head } from 'react-helmet';
import Headings from 'components/UI/Headings';
import CartList from 'components/Cart/CartList';
import OrderSummary from 'components/OrderSummary';
import ProductsList from 'components/Product/ProductsList';
import META from 'constants/meta';

const ShoppingCart = () => {
  return (
    <>
      <Head>
        <title>{META.SHOPPING_CART.title}</title>
        <meta name="description" content={META.SHOPPING_CART.description} />
      </Head>
      <div className="flex flex-col flex-1 w-full max-w-screen-xl min-h-screen px-5 mx-auto space">
        <Headings title="Shopping Cart" />

        <section className="w-full">
          <>
            <section className="grid w-full grid-cols-12 mb-5 space-y-5 sm:space-y-0 lg:space-x-10">
              <CartList />
              <OrderSummary />
            </section>

            <ProductsList count={10} random isCarousel />
          </>
        </section>
      </div>
    </>
  );
};

ShoppingCart.propTypes = {
  items: PropTypes.array,
  total: PropTypes.number,
  quantity: PropTypes.number,
};

export default ShoppingCart;
