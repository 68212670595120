import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCart } from 'context/useCart';

import Icon from 'components/UI/Icon';

const CartListItem = ({ item, readOnly }) => {
  const { id, name, price, image, quantity } = item;
  const { updateCartItemQuantity, removeCartItem, getItemFromCart } = useCart();
  const [itemQuantity, setItemQuantity] = useState(quantity);

  const handleChange = (e) => {
    const item = getItemFromCart(id);

    setItemQuantity(e.target.value);
    updateCartItemQuantity({ ...item, quantity: Number(e.target.value) });
  };

  const handleRemove = (item) => {
    removeCartItem(item);
  };

  useEffect(() => {
    setItemQuantity(quantity);
  }, [quantity]);

  return (
    <li className="flex items-center w-full h-[8rem] border-b-4 border-gray-50 overflow-hidden">
      <div className="flex items-center justify-center w-[5rem] px-4 h-auto">
        <img src={image} alt={name} className="object-contain object-center w-12 max-w-full" />
      </div>
      <div className="flex flex-col justify-between w-full pl-4 pr-6 space-y-2 sm:space-y-0 sm:items-end sm:flex-row ">
        <div className="w-auto">
          <h3 className="mb-2 text-base font-medium text-blue-500 capitalize font-roboto">
            {name}
          </h3>
          {/* <p className="mb-2 text-sm font-normal text-blue-500 font-roboto" title={description}>
            {truncate(description, 60)}
          </p> */}
          {!readOnly ? (
            <div className="flex w-full space-x-4">
              <select
                className="flex items-center justify-center w-14 py-2 px-1 leading-tight text-base bg-white border border-gray-200 rounded-md h-[38px] text-gray-400"
                onChange={handleChange}
                value={itemQuantity}>
                {[...Array(99).keys()].map((value) => (
                  <option key={value} value={value + 1}>
                    {value + 1}
                  </option>
                ))}
              </select>
              <div className="w-1 h-auto mx-2 bg-gray-100" />
              <button className="flex items-center space-x-2" onClick={() => handleRemove(item)}>
                <Icon name="trash" size="sm" color="primary" aria-hidden="true" />
                <span className="font-medium text-gray-500 ">Delete</span>
              </button>
            </div>
          ) : (
            <div className="inline-flex items-center justify-center h-6 p-1 text-base bg-blue-100 rounded min-w-[1.5rem] font-roboto">
              {itemQuantity}
            </div>
          )}
        </div>
        <div className="text-2xl font-bold tracking-wider text-blue-500 font-roboto">${price}</div>
      </div>
    </li>
  );
};

CartListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.string,
    quantity: PropTypes.number,
  }),
  removeFromCart: PropTypes.func,
  addToCart: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default CartListItem;
