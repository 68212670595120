import Payment from 'payment';

/**
 * @title Clear card number
 * @description Clear card number
 * @param {string} cardNumber - Card number
 * @returns {string} - Cleared card number
 * @example
 * clearCardNumber('1234567890123456');
 */
function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

/**
 * @title Format a credit card number
 * @description Formats cards number with spaces and dashes
 * @param {string} cardNumber - Card number
 * @returns {string} - Formatted card number
 * @example
 * formatCreditCardNumber('1234567890123456');
 */
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        15
      )}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        14
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12
      )} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

/**
 * @title Credit card type
 * @description Returns credit card type based on card number
 * @param {string} cc - Card number
 * @returns {string} - Credit card type
 * @example
 * creditCardType('1234567890123456');
 * // => 'visa'
 */
export function creditCardType(cc) {
  const issuer = Payment.fns.cardType(cc);
  return issuer;
}

/**
 * @title Format CVV
 * @description Formats CVV with a space
 * @param {string} CVV - CVV
 * @returns {string} - Formatted CVV
 * @example
 * formatCVV('123');
 */
export function formatCVV(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

/**
 * @title Format expiration date
 * @description Formats expiration date with a slash
 * @param {string} expirationDate - Expiration date
 * @returns {string} - Formatted expiration date
 * @example
 * formatExpirationDate('12/20');
 */
export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

/**
 * @title Format form data
 * @description Formats form data with a new line
 * @param {object} data - Form data
 * @returns {string} - Formatted form data
 * @example
 * formatFormData(data);
 */
export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

/**
 * @title Mask for credit card number
 * @description Returns a regex to be used to mask credit card number
 * @returns {string} - Regex
 */
export const creditCardMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
];
/**
 * @title Mask for expiration date
 * @description Returns a regex to be used to mask expiration date
 * @returns {string} - Regex
 */
export const expiryMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
/**
 * @title Mask for CVV
 * @description Returns a regex to be used to mask CVV
 * @returns {string} - Regex
 */
export const cvvMask = [/[0-9]/, /[0-9]/, /[0-9]/];

/**
 * @title Mask for ZIP code
 * @description Returns a regex to be used to mask ZIP code
 * @returns {string} - Regex
 */
export const zipMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

/**
 * @title Mask for ZIP code
 * @description Returns a regex to be used to mask ZIP code
 * @returns {string} - Regex
 */
export const phoneMask = [
  '(',
  /[0-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /[0-9]/,
  /\d/,
  /\d/,
  '-',
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
];
