import PropTypes from 'prop-types';

const ProductsListItem = ({ product }) => {
  const { name, image, price, quantity } = product;
  return (
    <li className="flex items-center justify-between w-full h-[8rem] border-b-4 border-gray-50 bg-white overflow-hidden rounded px-2">
      <div className="flex items-center text-blue-500 sm:w-10/12 ">
        <div className="flex items-center justify-center w-[102px]">
          <img
            src={image}
            alt={name}
            className="object-contain object-center w-[102px] h-[102px]"
          />
        </div>
        <div className="flex flex-col w-10/12 space-y-2">
          <h1 aria-label="product name">{name}</h1>
          <div className="flex items-center justify-center w-6 h-6 bg-blue-100 rounded">
            <span className="text-sm text-blue-500 font-regular font-roboto">{quantity}</span>
          </div>
        </div>
      </div>
      <section className="flex items-end justify-center py-5 sm:w-2/12">
        <h2 className="text-2xl font-bold text-blue-600">${price.toFixed(2)}</h2>
      </section>
    </li>
  );
};

ProductsListItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
};

export default ProductsListItem;
