import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { BaseUrl } from '../api/core/BaseService';
import PropTypes from 'prop-types';


function StripeWrapper({isVisible, amount, customer, onSuccess}) {
  const [stripePromise, setStripePromise] = useState('');
  const [stripePublishKey, setStripePublishKey] = useState('');

  useEffect(() => {
    // Fetch the publishable key from your server
    const fetchPublishableKey = async () => {
      try {
        const response = await fetch(`${BaseUrl}stripe/settings`)
        const data = await response.json();
        const stripe = loadStripe(data.stripe_publish_key);
        setStripePromise(stripe);
        setStripePublishKey(data.stripe_publish_key);
      } catch (error) {
        console.error('Error fetching publishable key:', error);
      }
    };

    fetchPublishableKey();
  }, []);
  if (!stripePromise && stripePublishKey) {
    // Optionally render a loading state while the publishable key is being fetched
    return <div>Loading...</div>;
  }

  if(!isVisible) {
    return null;
  }
  console.log(customer)
  return (
    <Elements stripe={stripePromise}>
        <StripeCheckoutForm amount={amount} customer={customer} onSuccess={onSuccess}/>
    </Elements>
  );
}

StripeWrapper.propTypes = {
  amount: PropTypes.number,
  customer: PropTypes.object,
  isVisible: PropTypes.bool,
  onSuccess: PropTypes.func
};

export default StripeWrapper;
