import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import AddressCard from './AddressCard';

const mapAddressHandler = (values) => {
  return {
    name: values.name,
    street1: values.street1,
    street2: values.street2 || '',
    city: values.city,
    state: values.state,
    zip: values.zip,
    phone: values.phone,
    email: values.email,
  };
};

const AddressCards = ({ readOnly, onEdit }) => {
  const checkout = useSelector((state) => state.checkout);
  const [customerAddress, setCustomerAddress] = useState({});

  const handleCustomerEdit = (values) => {
    const mappedAddress = mapAddressHandler(values);
    setCustomerAddress({});

    setCustomerAddress(mappedAddress);
    onEdit(mappedAddress);
  };

  useEffect(() => {
    setCustomerAddress(mapAddressHandler(checkout.customer));
  }, [checkout]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <AddressCard
        addressDetails={customerAddress}
        title="Shipping"
        onEdit={handleCustomerEdit}
        readOnly={readOnly}
      />
    </div>
  );
};

AddressCards.propTypes = {
  readOnly: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default AddressCards;
