import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import AddressCardItem from './AddressCardItem';
import AddressForm from './AddressForm';
import Icon from 'components/UI/Icon';

const AddressCardsList = ({ addressDetails, title, onEdit, readOnly }) => {
  const [init, setInit] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const { name, street, city, state, zip, email, phone } = addressDetails;

  const handleInit = () => {
    setInit(false);
    setIsEditing(true);
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = (values) => {
    handleEdit();
    setIsEditing(false);
    onEdit(values);
  };

  const isInvalid = !name || !street || !city || !state || !zip || !email || !phone;
  useEffect(() => {
    if (readOnly) {
      setIsEditing(false);
      setInit(false);
    } else if (isInvalid) {
      setIsEditing(false);
      setInit(true);
    }
  }, [readOnly]);

  const CardInitialState = () => (
    <section className="flex flex-col items-center justify-center space-y-2 bg-blue-100 rounded-md h-36">
      <h1 className="text-blue-500 font-bold text-xl mb-2.5">Add {title} address</h1>
      <Icon
        onClick={handleInit}
        size="lg"
        name="plus"
        color="primary"
        styles="bg-blue-200 rounded-full ring-2 ring-blue-200 hover:bg-blue-500 hover:text-white cursor-pointer hover:ring-blue-500"
      />
    </section>
  );

  const EditAddressCard = () => (
    <AddressForm
      title={title}
      addressDetails={addressDetails}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );

  const AddressCard = () => (
    <AddressCardItem
      title={title}
      addressDetails={addressDetails}
      onEdit={handleEdit}
      readOnly={readOnly}
    />
  );

  const AddressCardController = () => {
    if (init && Object.values(addressDetails)[0] === undefined) {
      return <CardInitialState />;
    }

    if (isEditing && !readOnly) {
      return <EditAddressCard />;
    }

    return <AddressCard />;
  };

  return (
    <div className="col-span-12 mb-6 fade sm:col-span-8">
      <AddressCardController />
    </div>
  );
};

AddressCardsList.propTypes = {
  title: PropTypes.string,
  addressDetails: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default AddressCardsList;
