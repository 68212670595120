import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useCart } from 'context/useCart';
import { useWindowSize } from 'react-use';
import { truncate } from 'utils/helpers';

import Button from 'components/UI/Button';

const ProductsListItem = ({ item, className }) => {
  const { id, image, name, description, price } = item;
  const { addItemToCart, updateCartItemQuantity, inCartItem, getItemFromCart } = useCart();
  const { width } = useWindowSize();
  const [quantity, setQuantity] = useState(1);
  const [truncateLength, setTruncateLength] = useState(0);
  const toastId = useRef(null);

  const itemQuantity = () => {
    if (inCartItem(item.id)) {
      return Number(getItemFromCart(item.id).quantity);
    }
    return Number(quantity);
  };

  const truncateLengthController = () => {
    switch (true) {
      case width < 640:
        return setTruncateLength(100);
      case width < 768:
        return setTruncateLength(80);
      case width < 1024:
        return setTruncateLength(30);
      case width < 1600:
        return setTruncateLength(40);
      case width < 1980:
        return setTruncateLength(50);
      default:
        return setTruncateLength(60);
    }
  };

  const handleAddToCart = (item) => {
    if (itemQuantity() >= 99) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warn('You can only add 99 items at a time.');
      }
      return;
    }

    if (inCartItem(item.id)) {
      setQuantity(itemQuantity() + 1);
      return updateCartItemQuantity({ ...item, quantity: itemQuantity() + 1 });
    }

    return addItemToCart(item, 1);
  };

  useEffect(() => {
    truncateLengthController();
  }, [width]);

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  return (
    <li
      className={`bg-white px-6 py-4 flex justify-center items-center flex-col col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-full max-h-[28rem] border-b-2 last:border-b-0 rounded border-gray-100 text-base font-normal font-roboto ${className}`}>
      <div className="flex flex-col items-center justify-between w-full h-full">
        <div className="flex items-center justify-center w-32 h-32 mb-8 text-blue-500 select-none">
          <img aria-hidden="true" className="object-contain w-full h-full" src={image} alt={name} />
        </div>
        <div className="w-full">
          <section className="flex flex-col mb-2">
            <h1 aria-label="Product name" className="mb-1 text-xl font-bold text-gray-600">
              {name}
            </h1>
            <p
              title={description}
              className="text-sm font-normal leading-normal text-gray-500 min-h-[3rem]">
              {truncate(description, truncateLength)}
            </p>
          </section>
          <section className="flex mb-4">
            <h2 className="text-2xl font-bold text-gray-600">${price.toFixed(2)}</h2>
          </section>
          <Button color="primary" rounded="md" size="lg" onClick={() => handleAddToCart(item)}>
            {inCartItem(id)
              ? `${itemQuantity()} bag${itemQuantity() > 1 ? 's' : ''} added `
              : 'Add to Cart'}
          </Button>
        </div>
      </div>
    </li>
  );
};

ProductsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.string,
    quantity: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default ProductsListItem;
