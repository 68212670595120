import { getProducts as getAllProducts } from 'utils/api/products';

import { GET_PRODUCTS, PRODUCTS_ERROR } from '../types';

export const getProducts = () => async (dispatch) => {
  try {
    const Products = await getAllProducts();

    dispatch({
      payload: Products,
      type: GET_PRODUCTS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: PRODUCTS_ERROR,
    });
  }
};
