import { Helmet as Head } from 'react-helmet';
import Headings from 'components/UI/Headings';
import ProductsList from 'components/Product/ProductsList';
import META from 'constants/meta';

const Home = () => {
  return (
    <>
      <Head>
        <title>{META.HOME.title}</title>
        <meta name="description" content={META.HOME.description} />
      </Head>
      <div className="w-full max-w-screen-xl min-h-screen px-5 mx-auto">
        <Headings title="Popcorn fall" />

        <ProductsList hideTitle />
      </div>
    </>
  );
};

export default Home;
