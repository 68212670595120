import Service from 'api/Service';

class CheckoutService extends Service {
  apiResource = '/sales';

  /**
   * @title:  Checkout Order
   * @description:  This function signs in the user
   * @param {Object} order
   * @returns {Object}
   * @example:
   * const checkout = await CheckoutService.checkoutOrder({
      "screenname": "",
      "amount": 0,
      "creditCardToken": "",
      "customer": {
          "name": "",
          "street1": "",
          "street2": ",
          "city": "",
          "state": "",
          "zip": "",
          "email": "",
          "phone": ""
      },
      "items": [
          {
              "sku": "",
              "quantity": 0
          }
      ]
  });
   **/
  checkoutOrder = async (order) => {
    try {
      const res = this.post('/stripe-web', order);
      return res;
    } catch (error) {
      console.log(error);
    }
  };
}

export default new CheckoutService();
