import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CartListItem from './CartListItem';
import Icon from 'components/UI/Icon';
import Button from 'components/UI/Button';

import { useCart } from 'context/useCart';

const CartList = ({ readOnly }) => {
  const navigate = useNavigate();

  const { items, isEmpty } = useCart();

  const buyMoreHandler = () => {
    navigate('/');
  };

  const EmptyState = () => (
    <section className="col-span-12 overflow-hidden lg:col-span-8 lg:mb-0 h-[17rem] bg-white flex items-center justify-center rounded-md">
      <div className="flex flex-col items-center justify-center mx-auto px-9 w-96 h-72">
        <div className="bg-blue-100 rounded-full p-1 h-[4rem] w-[4rem] flex items-center justify-center mb-[1.375rem]">
          <Icon name="shopping-cart" size="lg" color="primary" />
        </div>
        <h2 className="mb-[1.375rem] text-[1.375rem] font-bold text-blue-600">
          Time to add popcorn!
        </h2>
        <Button size="lg" color="primary" rounded="sm" onClick={() => buyMoreHandler()}>
          <span className="text-base font-medium">View all popcorn</span>
        </Button>
      </div>
    </section>
  );

  const CartItems = () =>
    items.map((item, idx) => {
      return <CartListItem key={idx} item={item} readOnly={readOnly} />;
    });

  return (
    <section className="col-span-12 overflow-hidden lg:col-span-8 lg:mb-0">
      <h1 className="mb-4 text-xl font-bold text-blue-600 font-roboto">My Items</h1>

      {!isEmpty ? (
        <ul className="w-full overflow-x-hidden bg-white rounded max-h-[35.6rem] scroll-smooth">
          <CartItems />
        </ul>
      ) : (
        <EmptyState />
      )}
    </section>
  );
};

CartList.propTypes = {
  products: PropTypes.array,
  quantity: PropTypes.number,
  total: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default CartList;
