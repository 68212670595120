import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BaseUrl } from '../api/core/BaseService';
import PropTypes from 'prop-types';


function StripeCheckoutForm({amount, customer, onSuccess}) {
  const [cardComplete, setCardComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    setIsProcessing(true)

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
        address: {
          line1: customer.street1,
          city: customer.city,
          state: customer.state,
          postal_code: customer.zip,
        },
      }
    });

    if (error) {
      setErrorMsg(error?.message || 'Unable to process');
      setIsProcessing(false)
      console.error(error);
    } else {
      console.log('Payment Method created:', paymentMethod);

      // Now, you need to create a PaymentIntent on the server and confirm the payment
      // You can send the paymentMethod.id to your server to create and confirm the PaymentIntent
      const response = await fetch(`${BaseUrl}stripe/payment_intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: amount * 100,
          capture_method: 'manual'
        }),
      });

      const paymentIntentResponse = await response.json();

      if (paymentIntentResponse.error) {
        setErrorMsg('unable to process transaction')
        setIsProcessing(false)
        console.error(paymentIntentResponse.error);
      } else {
        // Confirm the payment on the client
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
          paymentIntentResponse.clientSecret
        );

        if (confirmError) {
          setErrorMsg(confirmError?.message || 'Unable to process');
          setIsProcessing(false);
          console.error(confirmError);
        } else {
          console.log('Payment Intent confirmed:', paymentIntent);
          onSuccess(paymentIntent.id)
          // Handle post-payment actions (e.g., redirect, display success message)
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p style={{color: '#909090'}}>Enter Credit Card Info</p>
      <div style={{padding: 10, borderRadius: 10, backgroundColor: 'white'}}>
        <CardElement
          onChange={(event) => {
            setCardComplete(event.complete);
            setErrorMsg(event.error ? event.error.message : null);
          }}
        />
      {errorMsg && <div style={{color: 'red'}}>{errorMsg}</div>}
      </div>
      <button
        style={{marginTop: 10, width: '100%', height: 50, color: 'white',
          backgroundColor: `${isProcessing|| !cardComplete ? '#ececec' : '#CE1126'}`,
          display: 'block',
          fontSize: 24, fontWeight: 'bold', borderRadius: 10}}
        type="submit" disabled={!stripe || !cardComplete && !isProcessing}>Pay</button>
    </form>
  );
}
StripeCheckoutForm.propTypes = {
  amount: PropTypes.number,
  isVisible: PropTypes.bool,
  customer: PropTypes.object,
  onSuccess: PropTypes.func
};
export default StripeCheckoutForm;
