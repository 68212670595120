import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet as Head } from 'react-helmet';
import META from 'constants/meta';

import Headings from 'components/UI/Headings';
// import Icon from 'components/UI/Icon';
import CheckoutList from 'components/Checkout/CheckoutList';
import OrderSummary from 'components/OrderSummary';
import ProductsList from 'components/Product/ProductsList';

const OrderCompletedView = () => {
  const checkout = useSelector((state) => state.checkout);
  const navigate = useNavigate();

  useEffect(() => {
    if (checkout.products.length === 0) {
      navigate('/');
    }
  }, [checkout.products]);

  return (
    <>
      <Head>
        <title>{META.ORDER_COMPLETED.title}</title>
        <meta name="description" content={META.ORDER_COMPLETED.description} />
      </Head>
      <section className="w-full max-w-screen-xl min-h-screen px-5 mx-auto">
        <Headings
          title="Checkout Completed"
          description="Your order has been placed. Thank you for your purchase."
        />

        <div className="grid w-full grid-cols-12 mx-auto mb-10 gap-x-4">
          <div className="flex flex-col w-full max-w-screen-xl col-span-12 mx-auto sm:col-span-8">
            <div className="flex justify-between mb-4">
              <h2 className="text-xl font-bold text-blue-600 font-roboto">My Items</h2>
              {/* <div className="flex items-center justify-center space-x-2">
              <Icon name="calendar" color="dark" size="sm" />
              <time
              className="text-base font-normal text-gray-500 font-roboto"
              dateTime="2022-06-03">
              Delivered date June 3rd, 2022
              </time>
            </div> */}
            </div>
            <CheckoutList />
          </div>
          <OrderSummary readOnly />
        </div>

        <ProductsList count={10} random isCarousel />
      </section>
    </>
  );
};

export default OrderCompletedView;
