const TITLE = 'Colby Ridge Popcorn';
const META = {
  // Home meta
  HOME: {
    title: `${TITLE}`,
    description: 'Welcome to the Colby Ridge Popcorn.',
  },
  // Checkout meta
  CHECKOUT: {
    title: `${TITLE} - Checkout`,
    description: 'Checkout your order.',
  },
  // Order completed meta
  ORDER_COMPLETED: {
    title: `${TITLE} - Order Completed`,
    description: 'Your order has been successfully completed.',
  },
  SHOPPING_CART: {
    title: `${TITLE} - Your Shopping Cart`,
    description: 'Shopping cart.',
  },
};
export default META;
