import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from 'store/actions/productAction';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import { shuffle } from 'utils/helpers';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';
import ProductsListItem from './ProductsListItem';

const ProductsList = ({ count, random, isCarousel, hideTitle }) => {
  const [inventory, setInventory] = useState([]);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [slidesPerView, setSlidesPerView] = useState(width);
  const { isLoading, error, products } = useSelector((state) => state.productsList);

  const productsListController = (products) => {
    switch (true) {
      case random && count > 0:
        return shuffle(products.slice(0, count));
      case random && !count:
        return shuffle(products);
      case !random && count > 0:
        return products.slice(0, count);
      default:
        return products;
    }
  };

  const screenSizeController = () => {
    switch (true) {
      case width < 640:
        return setSlidesPerView(1.1);
      case width < 768:
        return setSlidesPerView(2.1);
      case width < 1024:
        return setSlidesPerView(3.1);
      case width < 1600:
        return setSlidesPerView(4.1);
      case width < 1980:
        return setSlidesPerView(5.15);
      default:
        return setSlidesPerView(4.1);
    }
  };

  const productsMemo = useMemo(() => productsListController(inventory), [inventory]);

  useEffect(() => {
    screenSizeController();
  }, [width]);

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getProducts());
    } else {
      setInventory(products);
    }
  }, [products]);

  const RenderProductsList = (inventory) => {
    switch (true) {
      case isLoading:
        return null;

      case error:
        return <h1>{error}</h1>;

      case inventory.length === 0:
        return <h1>No inventory found</h1>;

      default:
        return (
          <>
            {!isCarousel ? (
              <ul className="grid grid-cols-12 gap-4">
                {inventory.map((item, idx) => (
                  <ProductsListItem key={idx} item={item} />
                ))}
              </ul>
            ) : (
              <Swiper
                spaceBetween={16}
                slidesPerView={slidesPerView}
                loop={true}
                navigation={{
                  prevEl: '.carousel__prev-btn',
                  nextEl: '.carousel__next-btn',
                }}
                modules={[Navigation]}>
                <div className="carousel__prev-btn">
                  <Icon name="chevron" size="sm" />
                </div>
                <div className="flex flex-col items-center justify-center">
                  {inventory.map((item) => (
                    <SwiperSlide key={item.id}>
                      <ProductsListItem
                        item={item}
                        className={
                          isCarousel ? 'cursor-grab active:cursor-grabbing' : 'cursor-default'
                        }
                      />
                    </SwiperSlide>
                  ))}
                </div>
                <div className="carousel__next-btn">
                  <Icon name="chevron" size="sm" rotate="180" />
                </div>
              </Swiper>
            )}
          </>
        );
    }
  };

  return (
    <>
      <section className="flex flex-wrap w-full mt-5">
        {!hideTitle && (
          <div className="flex items-center justify-between py-2.5 w-full">
            <h1 className="mb-4 text-lg font-bold text-blue-500 md:text-xl font-encode">
              {pathname === '/order-completed' ? 'Continue shopping' : 'You may also like'}
            </h1>
            {pathname === '/order-completed' && (
              <Button
                className="flex items-center space-x-2 text-blue-500 font-encode"
                onClick={() => navigate('/')}>
                <span className="text-base font-bold hover:text-red-500">
                  View all popcorn bags
                </span>
                <Icon name="arrow" size="xs" rotate="180" />
              </Button>
            )}
          </div>
        )}
        <div className="flex items-center justify-center w-full">
          {RenderProductsList(productsMemo)}
        </div>
      </section>
    </>
  );
};

ProductsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  random: PropTypes.bool,
  isCarousel: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

export default ProductsList;
