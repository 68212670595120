import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCheckout } from 'store/actions/checkoutAction';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'context/useCart';
import { Helmet as Head } from 'react-helmet';

import Headings from 'components/UI/Headings';
import AddressCardForm from 'components/Checkout/AddressCardForm';
import CheckoutList from 'components/Checkout/CheckoutList';
import OrderSummary from 'components/OrderSummary';
import META from 'constants/meta';

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items } = useCart();

  const handleAddressOnEdit = (values) => {
    const removedEmptyValues = Object.keys(values).reduce((acc, key) => {
      if (values[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    dispatch(setCheckout({ ...removedEmptyValues, type: 'customer' }));
  };

  useEffect(() => {
    if (items.length === 0) {
      return navigate('/cart');
    }
  }, [items]);

  return (
    <>
      <Head>
        <title>{META.CHECKOUT.title}</title>
        <meta name="description" content={META.CHECKOUT.description} />
      </Head>
      <div className="w-full max-w-screen-xl px-5 mx-auto">
        <Headings title="Checkout" />

        <div className="flex flex-col flex-wrap">
          <AddressCardForm onEdit={handleAddressOnEdit} />
        </div>
      </div>
      <hr className="my-8 text-gray-200" />
      <div className="grid w-full max-w-screen-xl grid-cols-12 px-5 mx-auto">
        <section className="flex flex-col w-full max-w-screen-xl col-span-12 px-5 mx-auto sm:col-span-8">
          <div className="flex justify-between mb-6">
            <h2 className="text-xl font-bold text-blue-600 font-encode">My items</h2>
          </div>
          <CheckoutList />
        </section>
        <OrderSummary />
      </div>
    </>
  );
};

export default Checkout;
