import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
require('yup-phone');
import MaskedInput from 'react-text-mask';
import { zipMask, phoneMask } from 'utils/payment';

import Button from 'components/UI/Button';
import TextField from 'components/UI/form/TextField';
import SelectField from 'components/UI/form/SelectField';

import AllStates from 'data/allStates';

const AddressForm = ({ addressDetails, title, onSubmit, onCancel }) => {
  const { name, street1, street2, city, state, zip, email, phone } = addressDetails;
  const [isValid, setIsValid] = useState(false);

  const initialValues = {
    name: name || '',
    street1: street1 || '',
    street2: street2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
    email: email || '',
    phone: phone || '',
  };

  const isCardEmpty = Object.values(addressDetails).every((value) => !value);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name field is required'),
    street1: Yup.string().required('Address field is required'),
    city: Yup.string().required('City field is required'),
    state: Yup.string().required('State field is required'),
    zip: Yup.string().required('Zip field is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email field is required'),
  });

  const validate = async (values, errors) => {
    if (
      Object.keys(errors).length === 0 &&
      values.name.length > 0 &&
      values.zip.replace(/\D/g, '').length === 5 &&
      values.phone.replace(/\D/g, '').length === 10 &&
      values.city.length > 0 &&
      values.state.length === 2 &&
      values.street1.length > 0 &&
      values.email.length > 0 &&
      values.email.includes('@') &&
      values.email.includes('.')
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onSubmitHandler = (values) => {
    onSubmit(values);
  };

  const onCancelHandler = () => {
    onCancel();
  };

  return (
    <section className="w-full col-span-12 p-6 mb-6 bg-white rounded sm:col-span-6">
      <div className="mb-6">
        <h1 className="text-xl font-bold text-blue-500 font-roboto ">
          <span className="capitalize">{title}</span> address
        </h1>
      </div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, errors, touched }) => (
          <Form
            className="grid grid-cols-12 text-base font-normal gap-y-2 gap-x-4"
            onKeyUp={async () => validate(values, errors)}
            onFocus={async () => validate(values, errors)}
            onSubmit={async (e) => {
              e.preventDefault();
              onSubmitHandler(values);
            }}>
            <TextField
              label="Name"
              name="name"
              type="text"
              placeholder="What's your name?"
              required
              value={values.name}
              error={touched.name && errors.name}
            />
            <TextField
              label="Zip code"
              name="zip"
              required
              render={({ field, meta }) => (
                <MaskedInput
                  {...field}
                  mask={zipMask}
                  placeholder="00000"
                  type="text"
                  value={field.value}
                  className={`px-3 h-[42px] text-base font-normal border rounded-md font-roboto ${
                    meta.error && meta.touched
                      ? 'border-red-500 text-red-500 placeholder-red-500'
                      : 'text-gray-500 border-gray-400'
                  }`}
                  autoComplete="off"
                />
              )}
            />
            <TextField
              label="Street address 1"
              name="street1"
              type="text"
              placeholder="Street address 1"
              fullwidth={true}
              required
              value={values.street1}
              error={touched.street1 && errors.street1}
            />
            <TextField
              label="Street address 2"
              name="street2"
              type="text"
              placeholder="Street address 2"
              fullwidth={true}
              value={values.street2}
              error={touched.street2 && errors.street2}
            />

            <TextField
              label="City"
              name="city"
              type="text"
              placeholder="City"
              required
              value={values.city}
              error={touched.city && errors.city}
            />
            <SelectField
              label="State"
              name="state"
              required
              options={AllStates}
              value={values.state}
              error={touched.state && errors.state}
            />
            <TextField
              label="Phone Number"
              name="phone"
              required
              render={({ field, meta }) => (
                <MaskedInput
                  {...field}
                  mask={phoneMask}
                  placeholder="(000) 000-0000"
                  type="text"
                  value={field.value}
                  className={`px-3 h-[42px] text-base font-normal border rounded-md font-roboto ${
                    meta.error && meta.touched
                      ? 'border-red-500 text-red-500 placeholder-red-500'
                      : 'text-gray-500 border-gray-400'
                  }`}
                  autoComplete="off"
                />
              )}
            />

            <TextField
              label="Email Address"
              name="email"
              type="email"
              placeholder="What's your email address?"
              required
              value={values.email}
              error={touched.email && errors.email}
            />

            <div className="flex items-center justify-end col-span-12 ">
              <div className="flex space-x-4">
                <Button
                  color={isCardEmpty ? 'disabled' : 'secondary'}
                  rounded="md"
                  size="md"
                  onClick={onCancelHandler}
                  disabled={isCardEmpty}>
                  Cancel
                </Button>
                <Button
                  color={!isValid ? 'disabled' : 'primary'}
                  type="submit"
                  size="md"
                  disabled={!isValid}>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

AddressForm.propTypes = {
  addressDetails: PropTypes.object,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddressForm;
