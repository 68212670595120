import PropTypes from 'prop-types';

const Heading = ({ title, description }) => (
  <section className="flex flex-col flex-wrap mb-8">
    <h1 className="mb-3 text-4xl font-medium text-blue-500 font-slab">{title}</h1>
    <p className="text-base text-gray-500 font-roboto">{description}</p>
  </section>
);

Heading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Heading;
