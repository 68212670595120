import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCart } from 'context/useCart';

import CheckoutListItem from './CheckoutListItem.jsx';

const CheckoutList = () => {
  const { pathname } = useLocation();
  const { products } = useSelector((state) => state.checkout);
  const { items } = useCart();

  let itemsList;
  const RenderItemsList = () => {
    if (pathname === '/order-completed') {
      itemsList = products || [];
    } else {
      itemsList = items;
    }
    return itemsList.map((product, idx) => <CheckoutListItem key={idx} product={product} />);
  };

  return (
    <section className="flex flex-wrap w-full rounded-lg">
      <div className="w-full rounded-md">
        <ul className="bg-gray-100">
          <RenderItemsList />
        </ul>
      </div>
    </section>
  );
};
CheckoutList.propTypes = {
  products: PropTypes.any,
  onAddToCart: PropTypes.func,
};

export default CheckoutList;
