import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCart } from 'context/useCart';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { scoutCodeValidator } from 'utils/api/users';
import { checkoutOrder } from 'utils/api/checkout';
import { setCheckout } from 'store/actions/checkoutAction';

import { SET_CHECKOUT_TYPE, CHECKOUT_STATUS } from 'constants/index';

import Button from 'components/UI/Button';
import ScoutCodeField from 'components/UI/form/ScoutCodeField';
import StripeWrapper from '../stripe/StripeWrapper';


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const OrderSummary = ({ readOnly }) => {
  let query = useQuery();
  let id = query.get('id');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { cartTotal, isEmpty, items, clearCart } = useCart();
  const { customer, totalCost, scoutCode, scoutName, sales, sale } = useSelector(
    (state) => state.checkout
  );
  const [cartTotalCost, setCartTotalCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCard, setShowCard] = useState(false);
  console.log('sales', sales);
  console.log('sale', sale);
  console.log('isProcessing'), isProcessing;

  const handleContinue = async () => {
    dispatch(setCheckout({ status: CHECKOUT_STATUS.PROCESSING, type: SET_CHECKOUT_TYPE.STATUS }));
    return navigate('/checkout');
  };



  const handleScoutValidate = async () => {
      // Validates scoutCode
      const isScoutCodeValid = await scoutCodeValidator(scoutCode);
      if (!isScoutCodeValid.success) {
        setIsProcessing(false);
        return toast.error('Invalid scout code');
      }
      setShowCard(true);
  }
  const handleCheckout = async (payment_intent_id) => {
    setIsProcessing(true);
    try {
      // Mapped products
      const products = items.map((item) => ({
        sku: item.sku,
        quantity: item.quantity,
      }));

      // Order data
      const order = {
        screenname: scoutCode,
        scoutName: scoutName,
        customer,
        creditCardToken: payment_intent_id,
        items: products,
        amount: Number(cartTotalCost) + Number(shippingCost),
        shippingCharge: Number(shippingCost),
      };
      console.log('order', order);

      // Checkout order
      const { data } = await checkoutOrder(order);
      const { success: isCheckoutSuccess, sale, inventory, saleItems, errorMessage } = data;

      if (isCheckoutSuccess) {
        dispatch(setCheckout({ products: items, type: SET_CHECKOUT_TYPE.PRODUCTS }));
        dispatch(setCheckout({ totalCost: order.amount, type: SET_CHECKOUT_TYPE.TOTAL_COST }));
        dispatch(
          setCheckout({ shippingCost: Number(shippingCost), type: SET_CHECKOUT_TYPE.SHIPPING_COST })
        );
        dispatch(
          setCheckout({ sale: { sale, inventory, saleItems }, type: SET_CHECKOUT_TYPE.SALE })
        );
        dispatch(
          setCheckout({ status: CHECKOUT_STATUS.COMPLETED, type: SET_CHECKOUT_TYPE.STATUS })
        );
        clearCart();
        toast.success('Order placed successfully');
        navigate(`/order-completed?sn=${scoutCode}&id=${sale.id}`);
      } else {
        setIsProcessing(false);
        toast.error(errorMessage);
        return { success: false, errorMessage: errorMessage };
      }
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
      toast.error('Order failed, check your code please and try again');
    }
  };

  const isValid = () => {
    if (!customer?.name || !customer?.email || !customer?.phone || scoutCode?.length < 3) {
      return false;
    }
    const customerValuesLength = Object.values(customer).length;

    return customerValuesLength >= 6;
  };

  const totalCostController = () => {
    let total = 0;
    if (location.pathname !== '/order-completed') {
      total = cartTotal;
    } else {
      total = totalCost;
    }
    return Number(total).toFixed(2);
  };

  useEffect(() => {
    let total = totalCostController();
    let totalShippingConsideration = 0;
    items.forEach((a) => {
      console.log('totalShippingConsideration', totalShippingConsideration, a);
      totalShippingConsideration += a.sku === 'HERO' ? 0 : a.quantity * a.price;
    });
    console.log('totalShippingConsideration', totalShippingConsideration);
    let shippingCost =
      totalShippingConsideration > 50 ? 5 : totalShippingConsideration === 0 ? 0 : 10;
    setCartTotalCost(Number(total));
    setShippingCost(shippingCost);
  }, [cartTotal, totalCost]);

  const CheckoutAction = () => {
    // if (showCard) {
    //   return (
    //     <div>
    //       <SquarePayment
    //         amount={cartTotalCost + shippingCost}
    //         onSuccess={(token) => {
    //           handleCheckout(token).then((error) => {
    //             console.log('ERROR:', error);
    //             setErrorMessage(error?.errorMessage);
    //           });
    //         }}
    //       />
    //       <p style={{ color: 'red' }}>{errorMessage || ''}</p>
    //     </div>
    //   );
    // }
    return (
      !readOnly &&
      !showCard && (
        <Button
          size="lg"
          color={(location.pathname !== '/cart' && !isValid()) || isEmpty ? 'disabled' : 'primary'}
          onClick={() => {
            if (location.pathname === '/cart') {
              handleContinue();
            } else {
              handleScoutValidate()
            }
          }}
          disabled={location.pathname !== '/cart' ? !isValid() : '' || isEmpty}>
          <span className="text-lg font-medium font-encode">
            {location.pathname === '/cart'
              ? 'Process order'
              : `Place order`}
          </span>
        </Button>
      )
    );
  };

  return (
    <section className="col-span-12 space-y-4 sm:col-end-13 sm:col-span-7 lg:col-span-4">
      <h2 className="text-xl font-bold text-blue-600 font-encode">Order summary</h2>

      <section className="px-6 py-8 bg-blue-100 rounded-md ">
        <div className="flex items-center justify-between w-full px-10 mb-4">
          <h4 className="text-base font-light text-gray-500 font-encode">Subtotal:</h4>
          <span className="text-3xl font-semibold tracking-wider text-blue-500">
            ${cartTotalCost}
          </span>
        </div>
        <div className="flex items-center justify-between w-full px-10 mb-4">
          <h4 className="text-base font-light text-gray-500 font-encode">Shipping Cost:</h4>
          <span className="text-3xl font-semibold tracking-wider text-blue-500">
            ${shippingCost}
          </span>
        </div>
        <div className="flex items-center justify-between w-full px-10 mb-4">
          <h4 className="text-base font-light text-gray-500 font-encode">TOTAL:</h4>
          <span className="text-3xl font-semibold tracking-wider text-blue-500">
            ${shippingCost + cartTotalCost}
          </span>
        </div>
        <ScoutCodeField />
        <CheckoutAction />
        <StripeWrapper
          isVisible={showCard}
          amount={cartTotalCost + shippingCost}
          customer={customer}
          onSuccess={handleCheckout}
        />
        {
          /*
        <SquarePayment
          key={'square_test'}
          isVisible={showCard}
          isProcessing={isProcessing}
          amount={cartTotalCost + shippingCost}
          onSuccess={(token) => {
            handleCheckout(token).then((error) => {
              console.log('ERROR:', error);
            });
          }}
        />
        */
        }
      </section>
      {location.pathname === '/order-completed' && (
        <h4 className="flex items-center  w-full px-2 mb-[1.375rem]">
          Reference number #:
          <span className="ml-2 font-bold tracking-wider text-blue-500">{id}</span>
        </h4>
      )}
    </section>
  );
};

OrderSummary.propTypes = {
  total: PropTypes.number,
  quantity: PropTypes.number,
  onProcessOrder: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default OrderSummary;
