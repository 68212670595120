import ProductService from 'api/ProductService';

/**
 * @title Get all products
 * @description Returns all products
 * @returns {object} - All products
 * @example
 * getProducts();
 */
export const getProducts = async () => {
  return await ProductService.getProducts();
};
