import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon';

const AddressCardItem = ({ title, addressDetails, onEdit, readOnly }) => {
  const { phone, street1, street2, city, state, zip, email } = addressDetails;
  return (
    <div className="relative flex flex-col w-full p-6 overflow-hidden bg-white rounded">
      {!readOnly && (
        <div className="absolute text-blue-500 cursor-pointer top-6 right-6 hover:text-gray-800">
          <Icon
            name="edit-square"
            size="xs"
            alt="Edit address button"
            title="Edit address"
            onClick={onEdit}
          />
        </div>
      )}

      <section className="mb-6">
        <h2 className="text-xl font-bold text-blue-500 font-roboto ">
          <span className="capitalize">{title}</span> address
        </h2>
      </section>

      <div>
        <section className="mb-5">
          <h3 className="text-base font-bold text-blue-500">Address</h3>
          <p className="text-base font-normal leading-snug font-roboto">
            {street1}
            {street2 ? ` ${street2}` : ''}. {city} {state} {zip}.
          </p>
        </section>

        <section className="grid grid-cols-12 gap-6">
          {phone && (
            <div className="col-span-12 sm:col-span-6">
              <h3 className="text-base font-bold text-blue-500">Phone</h3>
              <span className="text-base font-normal leading-snug font-roboto">{phone}</span>
            </div>
          )}
          <div className="col-span-12 sm:col-span-6">
            <h3 className="text-base font-bold text-blue-500">Email</h3>
            <span className="text-base font-normal leading-snug font-roboto">{email}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

AddressCardItem.propTypes = {
  title: PropTypes.string,
  addressDetails: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default AddressCardItem;
