import CheckoutService from 'api/CheckoutService';

/**
 * @title Checkout Order
 * @description This function checks out the order
 * @param {Object} order
 * @returns {object} - The checkout response
 * @example
 * checkoutOrder(order);
 */
export const checkoutOrder = async (order) => {
  return await CheckoutService.checkoutOrder(order);
};
