/**
 * @title Shuffle array
 * @description Returns a shuffled array
 * @param {array} array - Array to be shuffled
 * @returns {array} - Shuffled array
 * @example
 * shuffle(array);
 */
export function shuffle(array) {
  var tmp,
    current,
    top = array.length;
  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
  return array;
}
/**
 * @title Truncate string
 * @description Truncates a string to a given length
 * @param {string} str - String to truncate
 * @param {number} length - Length to truncate to
 * @returns {string} - Truncated string
 * @example
 * truncateString('Hello World', 5);
 * // => 'Hello'
 */
export function truncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
